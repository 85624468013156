:root {
    --fs-contact-us-main: 1.4rem;
    --fs-contact-us-ledge: 1rem;
    --fs-contact-us-contacts-header: 1rem;
    --fs-contact-us-contacts-ledge: 0.8rem;
}

.header-color {
    color: white;
}

.text-color {
    color: #a0ccef;
}

.contact-us-hero-section {
    width: 100%;
    min-height: 30vh;
    background-color: #1983d8;
    gap: 3rem;
    padding: 2rem;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-us-main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-us-hero-section p {
    padding: 0;
    margin: 0;
}

.contact-us-header {
    font-size: var(--fs-contact-us-main);
}

.contact-us-contacts {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contact-us-phone,
.contact-us-email {
    justify-content: flex-start;
}

.contact-us-contacts-header {
    font-size: var(--fs-contact-us-contacts-header);
}

.contact-us-contacts-ledge {
    font-size: var(--fs-contact-us-contacts-ledge);
}

.contact-us-body {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.contact-us-body-header {
    flex-grow: 1;
    padding: 2.5rem 0 1.5rem 0;
    font-size: 1.4rem;
    font-weight: 600;
}

.contact-us-body form {
    width: 100%;
    flex-grow: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2.6rem;
}

.contact-us-body form input,
.contact-us-body form textarea {
    border: 1px solid #c3e0ff;
    background-color: #ebf4fe;
    padding: 5px 0 5px 0.5rem;
}

.contact-us-body form input {
    line-height: 2rem;
}

.contact-us-body label {
    font-size: 1.1rem;
    font-weight: 500;
}

.contact-us-form-element {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.required-field::after {
    content: "*";
    color: red;
    margin-left: 2px;
}

.contact-us-btn-parent {
    --outer-border: 2px solid #1983d8;

    padding: 2px;
    width: 100%;
    border-radius: 9px;
    border-left: var(--outer-border);
    border-right: var(--outer-border);
}

.contact-us-btn-submit {
    width: 100%;
    display: flex;
}

.contact-us-btn-submit button {
    flex-grow: 1;
}

@media (min-width : 768px) {
    .contact-us {
        display: flex;
        flex-direction: row;
    }

    .contact-us-hero-section,
    .contact-us-body {
        width: 50%;
    }

    .contact-us-hero-section {
        background-color: transparent;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 10%;
        padding-top: 40px;
    }

    .contact-us-main-content {
        align-items: flex-start;
    }

    .contact-us-contacts {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-color {
        color: #1983d8;
    }

    .text-color {
        color: #8b8b8b;
    }

    .contact-us-contacts {
        gap: 1.5rem;
    }

    .contact-us-underline {
        width: 60%;
        border-bottom: 1px solid rgb(135, 135, 135);
    }

    .contact-us-hero-section .contact-us-header {
        margin-bottom: 2rem;
    }

    .contact-us-header {
        color: black !important;
    }

    .contact-us-body form {
        gap: 2rem;
    }

    .contact-us-header,
    .contact-us-body-header {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .contact-us-body form input,
    .contact-us-body form textarea {
        border: 1px solid #c3e0ff;
        background-color: #ebf4fe;
        padding: 2px 0 2px 0.5rem;
    }

    .contact-us-body form textarea {
        padding: 5px 0 5px 0.5rem;
    }

    .contact-us-btn-parent {
        width: fit-content;
        padding: 2px;
    }

    .contact-us-btn-submit {
        width: fit-content;
    }

    .contact-us-btn-submit button {
        padding: 5px 45px;
    }
}
