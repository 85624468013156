:root {
    --colon-size: 30px;
}

.testimonial-card {
    width: 42ch;
    height: 30ch;
    position: relative;
    border: none;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    margin-bottom: 5px;
}

.testimonial-card--top {
    padding: 2rem 4rem;
    display: flex;
    align-items: flex-start;
    height: 22ch;
    background-color: #dcedff;
}

.testimonial-card-img {
    height: 50px;
    width: 50px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(18ch - 50px);
    background-color: transparent;
    border-radius: 50%;
    -webkit-box-shadow: 1px 31px 77px -29px rgba(0, 0, 0, 0.9);
    -moz-box-shadow: 1px 31px 77px -29px rgba(0, 0, 0, 0.9);
    box-shadow: 1px 31px 77px -29px rgba(0, 0, 0, 0.9);
}

.card-header {
    background: none;
    border: none;
    color: black;
    position: relative;
    font-size: 0.7rem;
    padding: 0;
}

.testimonial-card-text {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0;
}

.testimonial-card-text-muted {
    font-size: 0.6rem;
    margin-bottom: 2px;
}

.testimonial-card-info {
    height: 14ch;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width:300px) {
    :root {
        --colon-size: 14px;
    }

    .testimonial-card {
        width: 98%;
        height: 40ch;
    }

    .testimonial-card--top {
        padding: 0.5rem 1.8rem;
        height: 28ch;
    }

    .card-header {
        font-size: 0.7rem;
    }

    .testimonial-card-img {
        height: 80px;
        width: 80px;
        left: calc(50% - 40px);
        top: calc(26ch - 40px);
        box-shadow: 0 4px 20px 0 rgb(120, 120, 120);
    }

    .testimonial-card-info {
        height: 14ch;
    }
}

@media (min-width : 300px) and (max-width:550px) {
    .testimonial-card {
        font-size: 0.8rem;
        height: 50ch;
    }

    .testimonial-card--top {
        padding: 0.7rem 2.2rem;
        height: 33ch;
    }

    .card-header {
        font-size: 0.8rem;
    }

    .testimonial-card-img {
        top: calc(33ch - 50px);
    }

    .testimonial-card-info {
        height: 17ch;
    }
}
