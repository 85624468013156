.main-container {
  background-color: rgba(255, 255, 255, 0.644);
  /* height: 100vh; */
  /* border: 2px solid black; */
  padding: 1rem;
}

/* Navbar styling */

.dprofile-navbar {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  justify-content: space-between;
  /* background-color: white; */
}
.arrow-container {
  display: flex;
  box-shadow: 1px 1px 8px #cdcccc;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
}
.profile-heading {
  width: 14rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Payout Styling */
.payout {
  /* border: 1px solid rgb(85, 85, 0); */
  padding: 0.5rem;
  margin: 1rem auto;
}

.payout-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid yellow; */
  cursor: pointer;

  width: 100%;
  height: 10rem;
  position: relative;
  background-color: rgb(0, 47, 79);
  /* background-color: rgb(1, 62, 90);  */
  border-radius: 0.5rem;
}
.payout-details > div {
  margin: 0.3rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bolder;
}

.next-arrow {
  /* background-color: #cdcccc; */
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3rem;
  height: 2rem;
}
.animated-arrow {
  /* color: white; */
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.right-arrow1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  /* border: 1px solid red; */
  color: #ffffff6f;
  /* background-color: green; */
  transform: translateX(-5px);
  font-size: 1.5rem;
  /* -webkit-animation-delay: 3s; */
  animation-delay: 3s;
}
.right-arrow2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  /* background-color: red; */
  /* border: 1px solid yellow; */
  color: #ffffffdc;
  transform: translateX(-22px);
  font-size: 1.6rem;
  /* -webkit-animation-delay: 2s; */
  animation-delay: 2s;
}
.right-arrow3 {
  display: flex;
  justify-content: center;
  /* background-color: black; */
  align-items: center;
  width: 32px;
  height: 32px;
  /* border: 1px solid red; */
  color: white;
  transform: translateX(-40px);
  font-size: 1.7rem;
  /* -webkit-animation-delay: 1s; */
  animation-delay: 1s;
}
.sliding {
  /* position: absolute; */
  animation: slider 4s linear infinite;
}

@keyframes slider {
  0% {
    opacity: 0;
    transform: translate(0px);
  }
  20% {
    opacity: 1;
    transform: translate(-2px);
  }
  40% {
    opacity: 1;
    transform: translate(-3px);
  }
  80% {
    opacity: 1;
    transform: translate(3px);
  }
  100% {
    opacity: 0;
    transform: translate(2px);
  }
}

/* Profile info styling */

.doctor-profile {
  padding: 0.5rem;
  /* border: 1px solid green; */
}

label,
input {
  display: block;
  width: 100%;
}
label {
  font-weight: 500;
  font-size: 1.2rem;
}
input {
  padding: 1rem;
  height: 2.5rem;
  outline: none;
  border: 1px solid #e0dede;
  font-size: 1rem;
}

#gender,
#dob {
  width: 50%;

  background-color: white;
}
#dob {
  padding: 0.5rem;
}
.doctor-profile-info > div {
  margin: 1.5rem auto;
}

select {
  height: 2.5rem;
  padding: 0.7rem;
  border: 1px solid #e0dede;
}
