.accordion {
    width: 80%;
    max-width: 1000px;
    margin: 2rem auto;
    padding: 4rem auto;
}

.accordion-item {
    background-color: transparent;
    color: black;
    margin: 1rem 0;
    padding: 1rem;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078);
}

.accordion-item-header {
    font-size: 1.1rem;
    padding: 0.3rem 2rem 0.5rem 0.3rem;
    position: relative;
}
.accordion-item-header-blue {
    font-size: 1.1rem;
    padding: 0.3rem 2rem 0.5rem 0.3rem;
    position: relative;
}
.accordion-item-body {
    transition: all 1s ease-in-out;
}

.accordion-item-body-active {
    border-top: 1px solid rgba(0,0,0,0.1);
}

.accordion-item-header p {
    margin: 0;
}
.accordion-item-header-blue p{
    color: #007bff;
}
.faqButton {
    position: absolute;
    top: 0;
    right: 0;
    transform:rotate(180deg);
    width: 30px;
    height: 30px;
    color: rgba(0,0,0,0.6);
    border: none;
    background-color: transparent;
    transition: transform 0.5s ease-in-out;
}

.faqButton-active {
    position: absolute;
    top: 0;
    right: 0;
    color: #007bff;
    transform: rotate(360deg);
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    transition: transform 0.5s ease-in-out;
}

.accordion-item-body {
    font-size: 0.8rem;
    letter-spacing: 0.005rem;
    padding-top: 10px;
}

.accordion-item-body-content ul{
    list-style-type: none;
    color: black;
}
.accordion-item-body-content ul li:before {
    content: '-';
    position: absolute;
    margin-left: -20px;
}

.accordion-item-body li {
    font-size: 0.95rem;
}

@media (max-width:500px) {
    .accordion-item {
        padding: 0.4rem;
    }
    .accordion-item-header, .accordion-item-header-blue {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        padding-bottom: 5px;
    }
    .accordion-item-header p, .accordion-item-header-blue p {
        font-size: 1rem;
        padding: 0;
        padding-right: 15px;
        width: 90%;
    }
    .accordion-item-header button,.accordion-item-header-blue button {
        height: 20px;
        width: 20px;
    }

    .accordion-item-body-content li {
        font-size: 0.9rem;
    }

    .faqButton {
        transform: rotate(180deg);
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0; 
        padding: 0;
    }

    .faqButton-active {
        transform: rotate(360deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0; 
        padding: 0;
    }
}
