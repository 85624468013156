.otpVal {
  width: 35px;
  height: 30px;
  margin: 5px;
  border: none;
  background-color: #bbb;
  text-align: center;
  outline: none;
  border-radius: 5px;
  padding: 2px;
}
.vinput-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
}
.mobile-verifyerror {
  font-size: 0.75rem;
  /* background-color: red; */
  text-align: center;
  /* line-height: 15px; */
  color: #de4535;
  margin-top: -1rem;
}
.icon-spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(350deg);
  }
}
