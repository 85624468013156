:root {
    --fs-home: min(calc(0.9rem + 0.3vw), 1.2rem);
}

.home-content {
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #f6f6f6;
    background-image: url("../../images/Landing_Page_BG.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.home-content-img {
    max-height: 70vh;
    width: 100%;
    background-image: url('../../images/front2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;

}

.home-content-text img {
    display: flex;
    margin-left: 7.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
}
.home-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-content-text p {
    font-size: var(--fs-home);
    margin-left: -24rem;
}

.home-content-body {
    width: 40%;
    height: 25vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-container-btn-parent {
    --outer-border: 2px solid #1983D8;
    padding: 2px;
    margin: 0 auto;
    width: fit-content;
    border-radius: 9px;
    border-left: var(--outer-border);
    border-right: var(--outer-border);
}
.home-btn-div {
    margin-right: 11.2rem;
    margin-top: 15px;
}
.home-content-btn {
    font-size: var(--fs-home);
}

@media(max-width : 900px) {
    :root {
        --fs-home: calc(0.9rem + 0.4vw);
    }
    .home-content-text p {
        margin-left: 0rem !important;
    }
}

@media(min-width: 769px) {
    .home-content {
        height: calc(100vh - 70px);
        flex-direction: row;
    }

    .home-content-img {
        height: 100%;
        width: 60%;
    }
    .home-content-text p {
        font-size: var(--fs-home);
    }
}

@media screen and (max-width: 630px) {
    .home-content {
        position: relative;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
    }
    .home-content-img {
        min-height: 60vh;
    }
    .home-content-body {
        margin-top: 27rem;
        width: 100%;
        justify-content: center;
    }
    .home-content-text {
        flex-direction: column;
    }
    .home-content-text p {
        font-size: 1rem;
        z-index: 2;
        position: absolute;
        margin-top: 8px;
    }
    .home-content-text img {
        margin-right: 8rem;
        z-index: 1;
    }
    .home-btn-div {
        margin-right: 5rem;
    }
}
