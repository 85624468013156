:root {
  --bookphysio-header-text: 1.1rem;
}

.icon-spinner{
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(350deg);
  }
}

.center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.page-header {
  position: relative;
  height: 6.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #007bff;
  min-width: 100%;
}

.page-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.page-header-text {
  font-size: var(--bookphysio-header-text);
  color: white;
}

.page-header-img {
  height: 30px;
  width: 30px;
  position: relative;
  top: -2px;
}

.book-physio-form {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5.5rem auto;
  gap: 2.8rem;
  min-width: 0;
}

.book-physio-form>input {
  width: 100%;
  border: 1px solid #c3e0ff;
  background-color: #ebf4fe;
  line-height: 2.2rem;
  padding-left: 0.8rem;
}

.bookphysio-input {
  border: 1px solid #c3e0ff;
  background-color: #ebf4fe;
}

.bookphysio-booking-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bookphysio-booking-parent {
  width: 100%;
}

.bookphysio-booking-btn {
  width: 100%;
}

.bookphysio-mobile {
  display: block;
}

.bookphysio-desktop {
  display: none !important;
}

/* .btn-booking {
  padding-top: 0.5rem;
} */

.promo-code-box {
  width: 100%;
}

.promo-code {
  display: flex;
  width: 100%;
}


.promo-code input {
  box-sizing: border-box;
  width: 40%;
  margin: auto 1rem;
  border: none;
  border-bottom: 1.5px solid black;
  text-align: center;
  flex-grow: 1;
}

.promo-code input:focus {
  outline: none;
}

.btn-booking-promo-validate {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.booking-amt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.booking-amt-text {
  display: inline-block;
  min-width: 50px;
  text-align: right;
  border: 1px solid #c3e0ff;
  background-color: #ebf4fe;
  padding: 0.1rem 0.4rem;
  margin-left: 0.5rem;
  border-radius: 3px;
}

@media(max-width: 380px) {
  .book-physio-form {
    margin: 2.5rem auto;
  }
}

@media (min-width : 481px) {
  :root {
    --bookphysio-header-text: min(calc(0.9rem + 1vw), 1.3rem);
    --bookphysio-form-marginy: min(calc(1rem + 3vw), 4rem);
  }

  .page-header {
    height: max(calc(6.5rem - 3vw), 4rem);
  }

  .book-physio-form {
    box-sizing: border-box;
    width: 60%;
    max-width: 30rem;
    margin: var(--bookphysio-form-marginy) auto;
  }

  .bookphysio-mobile {
    display: none;
  }

  .bookphysio-desktop {
    display: block !important;
  }
}
