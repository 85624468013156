.condition {
    display: flex;
    justify-content: center;
}

.condition-card {
    width: 65%;
    position: relative;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.condition-card-image {
    width: 95%;
    margin: 7px auto 10px auto;
    object-fit: cover;
}

.condition-card-content {
    width: 90%;
    background-color: #f6f6f6;
    color: black;
    margin-top: 2px;
    font-size: 0.9rem;
    padding: 3px;
    margin-left: 5%;
    text-align: center;
}

@media screen and (max-width: 630px) {
    .condition {
        width: 100%;
    }
    .condition-card {
        width: 250px;
        height: 220px;
        margin: 0;
    }
}
