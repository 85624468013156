.thank-main-container{
    height: 100vh;
    background-color: #e1f5f1;
    padding: 2.3rem;
}
.thank-container{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    padding: 1rem;
    background-color: white;
    flex-direction: column;
    justify-content: center;
}
.phys-logo{
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;
}
.phys-logo .logo-img{
    height:2.5rem;
    width: 2.5rem;
}
.phys-logo .logo-txt{
    font-size: 1.8rem;
    margin-left: 0.50rem;
    color: rgb(161, 157, 157);
    align-items: center;
}
.thank-title{
    margin: 1.8rem;
    text-align: center;
}
.thank-title h1{
    font-size:3.5rem;
    font-weight:900;
}
.tick-img{
    display: flex;
    margin: 2rem;
    justify-content: center;
}
.tick-img img{
    height: 7rem;
}
.thank-content{
    width: 60%;
    margin: 2rem auto;
}
.thank-content p{
    text-align: center;
    line-height: 1rem;
    /* line-height-step: 1; */
}
.thank-container h5{
    position: fixed;
    bottom: 2rem;
    left: 3.5rem;
    
}

/* Media Queries ------------------ */
@media (max-width:900px){
    .thank-main-container{
        min-height: 90vh;
        padding: 1rem;
    }
    .thank-container{
        height: 90vh;
        padding: 0.75rem;
    }
    .phys-logo{
        margin-top: 0px;
        position: absolute;
        top: 2rem;
        left: 0;
        right: 0;
        justify-content: center;
    }
    .thank-title h1 {
        font-size: 2.5rem;
        font-weight: 900;
    }
    .tick-img{
        margin: 4rem;
    }
    .tick-img img {
        height: 6rem;   
    }
    .thank-content{
        width: 90%;
    }
    .thank-content p{
        margin: 0rem;
        line-height: 1.5rem !important;

    }
    .thank-container h5 {
        position: absolute;
        bottom: 1rem;
        /* margin-bottom: 0px; */
    }
}
