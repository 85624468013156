:root {
    --pd-btn-booking: clamp(0.63rem, calc(0.63rem + 0.5vw), 1.25rem);
}

.navbar {
    padding: 0;
    width: 100%;
    z-index: 1;
}

.nav-text {
    text-align: center;
}

.navbar-brand {
    margin-left: 1rem;
}

.font-background {
    background-color: rgb(208, 238, 254);
}

.btn-position {
    left: 60%;
}

.img-resize {
    height: 550px;
}

.btn-booking {
    color: white;
    padding: 3px var(--pd-btn-booking);
    border: 0px solid transparent;
    border-radius: 7px;
    background-color: #1983D8;
    box-sizing: border-box;
    transition: all 0.3s;
}

.btn-booking:hover {
    background-color: #0d6bcf;
}

.nav-logo {
    height: 70px;
}

.navbar-toggler {
    border: none;
}

.nav-btn-mob {
    display: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 480px) {
    .nav-logo {
        height: 60px;
    }
    .navbar-link {
        font-size: 0.8rem;
    }
}

@media (min-width : 481px) {
    .navbar {
        padding: 0;
        width: 100%;
        z-index: 1;
        position: sticky;
        top: 0;
    }

}

@media screen and (max-width: 630px) {
    .nav-btn-desk {
        display: none;
    }
    .nav-btn-mob {
        display: block;
    }
    .mob-book {
        border: none;
        background-color: #1983D8;
        border-radius: 7px;
    }
    .mob-book:hover {
        background-color: #0d6bcf;
    }
    .mob-btn-book {
        width: fit-content;
        padding: 2px;
        border-left: 2px solid #1983D8;
        border-right: 2px solid #1983D8;
        border-radius: 9px;
    }
}

/*

320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV

*/
