@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap);
@import url(https://www.gfxfile.com/Wmh/Axiforma.rar);
.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url('https://i.pinimg.com/originals/69/3a/6a/693a6ac031d56ed140365a02be40dd2a.jpg');
  background-size: cover;
  background-position: center center;
}

.Logo {
  margin-left: 38%;
}

.btn {
  background-color: #12AD2B;

}

.btn:hover {
  background-color: #fff;
  color: black;
  border: 2px solid #12AD2B;
}

.forgot {
  color: black;
}

:root {
    --pd-btn-booking: clamp(0.63rem, calc(0.63rem + 0.5vw), 1.25rem);
}

.navbar {
    padding: 0;
    width: 100%;
    z-index: 1;
}

.nav-text {
    text-align: center;
}

.navbar-brand {
    margin-left: 1rem;
}

.font-background {
    background-color: rgb(208, 238, 254);
}

.btn-position {
    left: 60%;
}

.img-resize {
    height: 550px;
}

.btn-booking {
    color: white;
    padding: 3px var(--pd-btn-booking);
    border: 0px solid transparent;
    border-radius: 7px;
    background-color: #1983D8;
    box-sizing: border-box;
    transition: all 0.3s;
}

.btn-booking:hover {
    background-color: #0d6bcf;
}

.nav-logo {
    height: 70px;
}

.navbar-toggler {
    border: none;
}

.nav-btn-mob {
    display: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 480px) {
    .nav-logo {
        height: 60px;
    }
    .navbar-link {
        font-size: 0.8rem;
    }
}

@media (min-width : 481px) {
    .navbar {
        padding: 0;
        width: 100%;
        z-index: 1;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

}

@media screen and (max-width: 630px) {
    .nav-btn-desk {
        display: none;
    }
    .nav-btn-mob {
        display: block;
    }
    .mob-book {
        border: none;
        background-color: #1983D8;
        border-radius: 7px;
    }
    .mob-book:hover {
        background-color: #0d6bcf;
    }
    .mob-btn-book {
        width: -webkit-fit-content;
        width: fit-content;
        padding: 2px;
        border-left: 2px solid #1983D8;
        border-right: 2px solid #1983D8;
        border-radius: 9px;
    }
}

/*

320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV

*/



:root {
    --fs-section-heading: min(calc(1.3rem + 0.5vw), 1.9rem);
    --fs-section-objective: min(calc(0.9rem + 0.2vw), 1.2rem);
}

* {
    font-family: 'Axiforma', 'sans-serif';
}


body {
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

.main-content-2 {
    background-color: #f6f6f6;
    margin: 0;
}

.header {
    padding: 50px 3vw 25px 3vw;
    text-align: center;
    color: #3b3b3b;
}

.header-content {
    font-size: var(--fs-section-heading);
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 8px;
}

.header-body {
    font-size: var(--fs-section-objective);
    margin-bottom: 12px;
}
.header-less-margin {
    margin-top: 0;
}
.underline {
    /* position: relative; */
    width: 100%;
    margin: 0 auto;
    margin: 2px;
}

.underline::after {
    position: absolute;
    content: "";
    height: 1.5px;
    width: 10%;
    background-color: #dda15e;
    left: 45%;
}

.arrow {
    height: 20px;
    width: 32px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.procedure-cards {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.procedure-cards-mob {
    display: none;
}

.desktop-footer {
    display: none;
}

.mobile-footer {
    display: block;
}

@media(min-width : 481px) {
    .procedure-cards {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media(min-width : 900px) {
    .desktop-footer {
        display: block;
    }
    .mobile-footer {
        display: none;
    }
}

@media screen and (max-width: 630px) {
    .procedure-cards {
        display: none;
    }
    .procedure-cards-mob {
        display: block;
    }
}

.service-card {
    width: min(calc(260px + 8vw), 320px);
    height: calc(300px - 1.5vw);
    overflow: hidden;
    margin: 1rem;
    position: relative;
    border-radius: 0.4rem;
    padding: 0.8rem;
}

.service-card-img-container {
    width: 100%;
    height: 80%;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
}

.service-card-img {
    width: 100%;
    height: 100%;
}

.service-card-body {
    font-size: clamp(0.95rem, calc(0.95rem + 0.3vw), 1rem);
    font-weight: 500;
    text-align: center;
    max-height: -webkit-fit-content;
    max-height: fit-content;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
}

.service-card:nth-of-type(4) img {
    width: 80%;
}

@media(max-width: 1200px) {
    .arrow {
        display: none;
    }
}

@media (min-width: 769px) {
    .service-card {
        width: max(calc(280px - 2vw), 240px);
        height: calc(270px - 1.8vw);
        margin: 0.5rem;
        align-items: baseline;
        transition: all 0.3s ease-in-out;
    }
}

@media screen and (max-width: 630px) {
    .service-card {
        margin-left: auto;
        margin-right: auto;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    }
}
.choose-us {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

.choose-us-content {
    margin: 2rem 0;
}

.choose-us-content img {
    height: 125px;
    width: 125px;
}

@media (max-width : 480px) {
    .choose-us-content img {
        height: 80px;
        width: 80px;
    }

    .choose-us-content h5 {
        font-size: 0.9rem;
    }
}

@media (min-width : 481px) and (max-width : 768px) {
    .choose-us-content img {
        height: 100px;
        width: 100px;
    }

    .choose-us-content h5 {
        font-size: 1rem;
    }
}

.mfooter-main-container {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.mfooter-sub-container {
    width: 100%;
}

.mfooter-container {
    color: white;
    text-align: center;
    background-color: #389AD1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mfooter-header {
    margin-top: 4px;
    width: 50%;
}

.mfooter-header h1 {
    font-weight: bolder;
    font-size: 40px;
}

.mfooter-header p {
    font-size: 13px;
}

a:hover {
    color: #bbb;
}

.mfooter-lists {
    font-size: 13px;
    width: 45%;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 2px;
}

.mfooter-container a {
    text-decoration: none;
    color: white;

}

.mfooter-social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    width: 15rem;
    border-bottom: 1px solid rgb(168, 166, 166);
}

.mfooter-social>a {
    color: white;
    background-color: #CC9B55;
    align-items: center;
    margin: 0.5rem;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    /* padding-bottom: 3px; */
    /* padding: 4px 4px 1px 1px; */
    border-radius: 50%;

}

.mfooter-copyright {
    text-align: center;
    font-size: 11px;
    color: rgb(124, 124, 124);
    margin-top: 10px;
}

.infoCarousel{
  width: 82%;
  margin: auto;
  margin-top: 1rem;
}
.infoCarousel .slick-slide:not(.slick-center) {
  /* opacity: 0.7; */
}
.infoCarousel .slick-slide .info-card-body-container {
  opacity: 1;
} 
.infoCarousel .slick-dots {
  position: relative;
} 

.infoCarousel .slick-dots li.slick-active button:before {
  color: #007bff;
}
.slick-dots li button:focus:before {
  opacity: 0.25;
}

.slick-dots li.slick-active button:focus:before,
.slick-dots li button:hover:before, .slick-dots li button:active:before {
  opacity: 1;
}
.infoCarousel .slick-dots li button:hover:after {
  color: #007bff;
} 
@media(max-width: 1025px) {

  .infoCarousel{
    width: 100%;
  }

  .infoCarousel .slick-center .info-card-body-container {
    animation: animatebottom 1s 1s forwards ease-in-out;
  }
    .slick-dots li button:focus:before {
      opacity: 0.25;
    }
  
    .slick-dots li.slick-active button:focus:before,
    .slick-dots li button:hover:before,
    .slick-dots li button:active:before {
      opacity: 1;
    }
  @keyframes animatebottom {
    from {
      bottom: -100px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }

}

.doctor-container{
    display:flex;
    justify-content: center;
}
.doctor-card{
    height: 20rem;
    width: 17rem;
    /* margin-bottom: 3rem; */
    /* padding: 1rem; */
    overflow: hidden;
    /* border: 2px solid black; */
    border-radius: 0.5rem;
}
.doctor-card img{
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    width: inherit;
    border-radius: 0.5rem;
    object-fit: cover;
}
.doctor-intro{
    height: 2.5rem;
    padding: 0.5rem;
    width: 17rem;
    position: absolute;
    bottom: 0rem;
    background: rgba(89, 102, 101, 0.5);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: white;
    transition: .5s;
    overflow: hidden;
}
.doctor-intro h1{
    text-align: center;
    font-size: 1.2rem;
    /* color: black; */
}
.doctor-intro p{
    font-size: 1rem;
    text-align: center;
    visibility:hidden;
    opacity: 0;
    /* color: black; */
}
.doctor-card:hover{
    cursor: pointer;
}
.doctor-card:hover .doctor-intro{
    height: 6rem;
    bottom: 0rem;
}
.doctor-card:hover p{
    opacity: 1;
    visibility: visible;
}
.doctor-card:hover img{
    /* transform: scale(1.1); */
    transition: 0.5s;
}
@media(max-width : 616px) {
    
}
@media(max-width : 1300px) and (min-width:6616px) {

    .doctor-card {
        margin: 3.2rem;
        width: 14rem;
    }

    .doctor-intro {
        width: 13.5rem;
    }

    .doctor-intro h1 {
        font-size: 1.1rem;
    }

    .doctor-card img {
        width: inherit;
        object-fit: cover;
    }
}
.slick-dots li.slick-active button:before {
    color: #bc6c25;
}

.slick-prev:before,
.slick-prev:after,
.slick-next:before,
.slick-next:after {
    color: #bc6c25;
}

.condition-carousel {
    width: 90%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 630px) {
    .condition-carousel {
        width: 100%;
    }
}

.condition {
    display: flex;
    justify-content: center;
}

.condition-card {
    width: 65%;
    position: relative;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.condition-card-image {
    width: 95%;
    margin: 7px auto 10px auto;
    object-fit: cover;
}

.condition-card-content {
    width: 90%;
    background-color: #f6f6f6;
    color: black;
    margin-top: 2px;
    font-size: 0.9rem;
    padding: 3px;
    margin-left: 5%;
    text-align: center;
}

@media screen and (max-width: 630px) {
    .condition {
        width: 100%;
    }
    .condition-card {
        width: 250px;
        height: 220px;
        margin: 0;
    }
}

.patient-testimonial-carousel .slick-dots li.slick-active button:before {
    color: #2082e4;
}

.patient-testimonial-carousel .slick-dots {
    position: relative;
}
.patient-testimonial-carousel {
    width: 85%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 630px) {
    .patient-testimonial-carousel {
        width: 100%;
    }
}

:root {
    --colon-size: 30px;
}

.testimonial-card {
    width: 42ch;
    height: 30ch;
    position: relative;
    border: none;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    margin-bottom: 5px;
}

.testimonial-card--top {
    padding: 2rem 4rem;
    display: flex;
    align-items: flex-start;
    height: 22ch;
    background-color: #dcedff;
}

.testimonial-card-img {
    height: 50px;
    width: 50px;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(18ch - 50px);
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 1px 31px 77px -29px rgba(0, 0, 0, 0.9);
}

.card-header {
    background: none;
    border: none;
    color: black;
    position: relative;
    font-size: 0.7rem;
    padding: 0;
}

.testimonial-card-text {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0;
}

.testimonial-card-text-muted {
    font-size: 0.6rem;
    margin-bottom: 2px;
}

.testimonial-card-info {
    height: 14ch;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width:300px) {
    :root {
        --colon-size: 14px;
    }

    .testimonial-card {
        width: 98%;
        height: 40ch;
    }

    .testimonial-card--top {
        padding: 0.5rem 1.8rem;
        height: 28ch;
    }

    .card-header {
        font-size: 0.7rem;
    }

    .testimonial-card-img {
        height: 80px;
        width: 80px;
        left: calc(50% - 40px);
        top: calc(26ch - 40px);
        box-shadow: 0 4px 20px 0 rgb(120, 120, 120);
    }

    .testimonial-card-info {
        height: 14ch;
    }
}

@media (min-width : 300px) and (max-width:550px) {
    .testimonial-card {
        font-size: 0.8rem;
        height: 50ch;
    }

    .testimonial-card--top {
        padding: 0.7rem 2.2rem;
        height: 33ch;
    }

    .card-header {
        font-size: 0.8rem;
    }

    .testimonial-card-img {
        top: calc(33ch - 50px);
    }

    .testimonial-card-info {
        height: 17ch;
    }
}

.footer-main-container {
    background-color: #e7f3ff;
    position: relative;
    top: 0;
    bottom: 0;
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 280px;
    overflow: hidden;
    z-index: 999;
}

.footer-main-container1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 30px 0px 0 60px;
}

.footer-sub-container {
    display: flex;
    justify-content: space-between;
}

.footer-max-container {
    display: flex;
    flex-direction: column;
}
.footer-right-container {
    margin-top: 30px;
}
.footer-list {
    font-size: 14px;
    display: flex;
    color: rgb(73, 71, 71);
}
.footer-right-list {
    font-size: 12px;
    display: flex;
    color: rgb(73, 71, 71);
    margin: 8px 0 8px 0;
}
.footer-sub-head {
    font-size: 15px;
}
.footer-left-container a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.footer-left-container {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.footer-right-container a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.footer-max-container h3 {
    margin-bottom: 20px;
}
.footer-max-container h3 a {
    color: black;
    text-decoration: none;
    font-size: smaller;
}
.footer-left-container a:hover {
    color: rgb(158, 153, 153);
}
.footer-right-container a:hover {
    color: rgb(158, 153, 153);
}

.footer-mini-container {
    flex-direction: column;
    display: flex;

    /* border: 1px solid red; */
    flex: 2 1;
}

.footers-icons>a {
    color: white;
    background-color: #CC9B55;
    height: 42px;
    width: 42px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.footers-icons {
    margin: 0;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}
.footers-icons a:hover {
    color: #1983D8;
}

:root {
    --fs-home: min(calc(0.9rem + 0.3vw), 1.2rem);
}

.home-content {
    width: 100%;
    height: calc(100vh - 70px);
    background-color: #f6f6f6;
    background-image: url(/static/media/Landing_Page_BG.0e65e035.png);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.home-content-img {
    max-height: 70vh;
    width: 100%;
    background-image: url(/static/media/front2.aa739cc9.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;

}

.home-content-text img {
    display: flex;
    margin-left: 7.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
}
.home-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-content-text p {
    font-size: var(--fs-home);
    margin-left: -24rem;
}

.home-content-body {
    width: 40%;
    height: 25vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-container-btn-parent {
    --outer-border: 2px solid #1983D8;
    padding: 2px;
    margin: 0 auto;
    width: -webkit-fit-content;
    width: fit-content;
    border-radius: 9px;
    border-left: var(--outer-border);
    border-right: var(--outer-border);
}
.home-btn-div {
    margin-right: 11.2rem;
    margin-top: 15px;
}
.home-content-btn {
    font-size: var(--fs-home);
}

@media(max-width : 900px) {
    :root {
        --fs-home: calc(0.9rem + 0.4vw);
    }
    .home-content-text p {
        margin-left: 0rem !important;
    }
}

@media(min-width: 769px) {
    .home-content {
        height: calc(100vh - 70px);
        flex-direction: row;
    }

    .home-content-img {
        height: 100%;
        width: 60%;
    }
    .home-content-text p {
        font-size: var(--fs-home);
    }
}

@media screen and (max-width: 630px) {
    .home-content {
        position: relative;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
    }
    .home-content-img {
        min-height: 60vh;
    }
    .home-content-body {
        margin-top: 27rem;
        width: 100%;
        justify-content: center;
    }
    .home-content-text {
        flex-direction: column;
    }
    .home-content-text p {
        font-size: 1rem;
        z-index: 2;
        position: absolute;
        margin-top: 8px;
    }
    .home-content-text img {
        margin-right: 8rem;
        z-index: 1;
    }
    .home-btn-div {
        margin-right: 5rem;
    }
}

.service-carousel .slick-dots li.slick-active button:before {
    color: #2082e4;
}

.service-carousel .slick-dots {
    position: relative;
}
/* .main-conf {
    height: 100vh;
    background-color: #E8F2F7
}

.main-conf h4 {
    font-size: 2rem;
    font-weight: bold;
}

.conf {
    background-color: white;
    padding: 3%;
    margin-top: 5%;
    height: auto;
    max-height: 60vh;
    width: 80vw;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.conf-booking {
    margin-top: 7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;



}

.conf-booking p {

    font-weight: bold;
    color: blue;
    font-size: 110%;
    display: inline-block;
    width: 220px;
    text-align: right;


}

.conf-booking h6 {
    padding-top: 3px;
    ;
    margin-left: 20px;
    display: inline-block;
    width: 220px;
    text-align: left;

}


@media only screen and (max-width: 768px) {
    .conf-booking h6 {
        font-size: 80%;
    }

    .conf-booking p {
        font-size: 90%
    }

    ;
}

@media only screen and (max-width: 432px) {
    .conf-booking h6 {
        font-size: 70%;
    }

    .conf-booking p {
        font-size: 80%
    }

    ;
} */

:root {
  --bookphysio-header-text: 1.1rem;
}

.icon-spinner{
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(350deg);
  }
}

.center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.page-header {
  position: relative;
  height: 6.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  background-color: #007bff;
  min-width: 100%;
}

.page-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.page-header-text {
  font-size: var(--bookphysio-header-text);
  color: white;
}

.page-header-img {
  height: 30px;
  width: 30px;
  position: relative;
  top: -2px;
}

.book-physio-form {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5.5rem auto;
  grid-gap: 2.8rem;
  gap: 2.8rem;
  min-width: 0;
}

.book-physio-form>input {
  width: 100%;
  border: 1px solid #c3e0ff;
  background-color: #ebf4fe;
  line-height: 2.2rem;
  padding-left: 0.8rem;
}

.bookphysio-input {
  border: 1px solid #c3e0ff;
  background-color: #ebf4fe;
}

.bookphysio-booking-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bookphysio-booking-parent {
  width: 100%;
}

.bookphysio-booking-btn {
  width: 100%;
}

.bookphysio-mobile {
  display: block;
}

.bookphysio-desktop {
  display: none !important;
}

/* .btn-booking {
  padding-top: 0.5rem;
} */

.promo-code-box {
  width: 100%;
}

.promo-code {
  display: flex;
  width: 100%;
}


.promo-code input {
  box-sizing: border-box;
  width: 40%;
  margin: auto 1rem;
  border: none;
  border-bottom: 1.5px solid black;
  text-align: center;
  flex-grow: 1;
}

.promo-code input:focus {
  outline: none;
}

.btn-booking-promo-validate {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.booking-amt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.booking-amt-text {
  display: inline-block;
  min-width: 50px;
  text-align: right;
  border: 1px solid #c3e0ff;
  background-color: #ebf4fe;
  padding: 0.1rem 0.4rem;
  margin-left: 0.5rem;
  border-radius: 3px;
}

@media(max-width: 380px) {
  .book-physio-form {
    margin: 2.5rem auto;
  }
}

@media (min-width : 481px) {
  :root {
    --bookphysio-header-text: min(calc(0.9rem + 1vw), 1.3rem);
    --bookphysio-form-marginy: min(calc(1rem + 3vw), 4rem);
  }

  .page-header {
    height: max(calc(6.5rem - 3vw), 4rem);
  }

  .book-physio-form {
    box-sizing: border-box;
    width: 60%;
    max-width: 30rem;
    margin: var(--bookphysio-form-marginy) auto;
  }

  .bookphysio-mobile {
    display: none;
  }

  .bookphysio-desktop {
    display: block !important;
  }
}

.confirm-details {
    min-height: 70vh;
    margin: auto;
    padding: 3rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;

    width: 85%;
}

.icon-spinner{
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
  
    100% {
        transform: rotate(350deg);
    }
  }

.confirm-details-element {
    width: 100%;

    display: flex;
    flex-direction: column;
    grid-gap: 0.3rem;
    gap: 0.3rem;
}

.confirm-details-element-label {
    font-size: 1.2rem;
    margin: 0;
    padding-left: 0.3rem;
    font-weight: 600;
}

.confirm-details-element-text {
    border: 1px solid #c3e0ff;
    background-color: #ebf4fe;
    font-size: 1.1rem;
    line-height: 2.5rem;
    padding-left: 0.3rem;
    color: #71777b;
}

.confirm-details-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.booking-amt-label {
    font-size: 1.1rem;
    margin: 2rem 1rem 2rem 0;
}

.booknow-parent {
    width: 100%;
    border-left: 2px solid #007bff;
    border-right: 2px solid #007bff;
    padding: 2px;
    border-radius: 9px;
}

.booknow-parent button {
    width: 100%;
}

@media(min-width : 481px) {
    .confirm-details {
        width: 60%;
        max-width: 30rem;
    }

    .booknow-parent {
        width: -webkit-fit-content;
        width: fit-content;
    }

    .booknow-parent button {
        width: -webkit-fit-content;
        width: fit-content;
    }
}

.thank-main-container{
    height: 100vh;
    background-color: #e1f5f1;
    padding: 2.3rem;
}
.thank-container{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    padding: 1rem;
    background-color: white;
    flex-direction: column;
    justify-content: center;
}
.phys-logo{
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;
}
.phys-logo .logo-img{
    height:2.5rem;
    width: 2.5rem;
}
.phys-logo .logo-txt{
    font-size: 1.8rem;
    margin-left: 0.50rem;
    color: rgb(161, 157, 157);
    align-items: center;
}
.thank-title{
    margin: 1.8rem;
    text-align: center;
}
.thank-title h1{
    font-size:3.5rem;
    font-weight:900;
}
.tick-img{
    display: flex;
    margin: 2rem;
    justify-content: center;
}
.tick-img img{
    height: 7rem;
}
.thank-content{
    width: 60%;
    margin: 2rem auto;
}
.thank-content p{
    text-align: center;
    line-height: 1rem;
    /* line-height-step: 1; */
}
.thank-container h5{
    position: fixed;
    bottom: 2rem;
    left: 3.5rem;
    
}

/* Media Queries ------------------ */
@media (max-width:900px){
    .thank-main-container{
        min-height: 90vh;
        padding: 1rem;
    }
    .thank-container{
        height: 90vh;
        padding: 0.75rem;
    }
    .phys-logo{
        margin-top: 0px;
        position: absolute;
        top: 2rem;
        left: 0;
        right: 0;
        justify-content: center;
    }
    .thank-title h1 {
        font-size: 2.5rem;
        font-weight: 900;
    }
    .tick-img{
        margin: 4rem;
    }
    .tick-img img {
        height: 6rem;   
    }
    .thank-content{
        width: 90%;
    }
    .thank-content p{
        margin: 0rem;
        line-height: 1.5rem !important;

    }
    .thank-container h5 {
        position: absolute;
        bottom: 1rem;
        /* margin-bottom: 0px; */
    }
}

:root {
    --fs-contact-us-main: 1.4rem;
    --fs-contact-us-ledge: 1rem;
    --fs-contact-us-contacts-header: 1rem;
    --fs-contact-us-contacts-ledge: 0.8rem;
}

.header-color {
    color: white;
}

.text-color {
    color: #a0ccef;
}

.contact-us-hero-section {
    width: 100%;
    min-height: 30vh;
    background-color: #1983d8;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 2rem;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-us-main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-us-hero-section p {
    padding: 0;
    margin: 0;
}

.contact-us-header {
    font-size: var(--fs-contact-us-main);
}

.contact-us-contacts {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contact-us-phone,
.contact-us-email {
    justify-content: flex-start;
}

.contact-us-contacts-header {
    font-size: var(--fs-contact-us-contacts-header);
}

.contact-us-contacts-ledge {
    font-size: var(--fs-contact-us-contacts-ledge);
}

.contact-us-body {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.contact-us-body-header {
    flex-grow: 1;
    padding: 2.5rem 0 1.5rem 0;
    font-size: 1.4rem;
    font-weight: 600;
}

.contact-us-body form {
    width: 100%;
    flex-grow: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 2.6rem;
    gap: 2.6rem;
}

.contact-us-body form input,
.contact-us-body form textarea {
    border: 1px solid #c3e0ff;
    background-color: #ebf4fe;
    padding: 5px 0 5px 0.5rem;
}

.contact-us-body form input {
    line-height: 2rem;
}

.contact-us-body label {
    font-size: 1.1rem;
    font-weight: 500;
}

.contact-us-form-element {
    width: 75%;
    display: flex;
    flex-direction: column;
}

.required-field::after {
    content: "*";
    color: red;
    margin-left: 2px;
}

.contact-us-btn-parent {
    --outer-border: 2px solid #1983d8;

    padding: 2px;
    width: 100%;
    border-radius: 9px;
    border-left: var(--outer-border);
    border-right: var(--outer-border);
}

.contact-us-btn-submit {
    width: 100%;
    display: flex;
}

.contact-us-btn-submit button {
    flex-grow: 1;
}

@media (min-width : 768px) {
    .contact-us {
        display: flex;
        flex-direction: row;
    }

    .contact-us-hero-section,
    .contact-us-body {
        width: 50%;
    }

    .contact-us-hero-section {
        background-color: transparent;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 10%;
        padding-top: 40px;
    }

    .contact-us-main-content {
        align-items: flex-start;
    }

    .contact-us-contacts {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-color {
        color: #1983d8;
    }

    .text-color {
        color: #8b8b8b;
    }

    .contact-us-contacts {
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }

    .contact-us-underline {
        width: 60%;
        border-bottom: 1px solid rgb(135, 135, 135);
    }

    .contact-us-hero-section .contact-us-header {
        margin-bottom: 2rem;
    }

    .contact-us-header {
        color: black !important;
    }

    .contact-us-body form {
        grid-gap: 2rem;
        gap: 2rem;
    }

    .contact-us-header,
    .contact-us-body-header {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .contact-us-body form input,
    .contact-us-body form textarea {
        border: 1px solid #c3e0ff;
        background-color: #ebf4fe;
        padding: 2px 0 2px 0.5rem;
    }

    .contact-us-body form textarea {
        padding: 5px 0 5px 0.5rem;
    }

    .contact-us-btn-parent {
        width: -webkit-fit-content;
        width: fit-content;
        padding: 2px;
    }

    .contact-us-btn-submit {
        width: -webkit-fit-content;
        width: fit-content;
    }

    .contact-us-btn-submit button {
        padding: 5px 45px;
    }
}

.bookphysio-booking-btn {
    width: 100%;
  }
  
.main-content {
  margin: 110px;
  text-align: center;
}

.home-button {
  padding: 5px var(--pd-btn-booking);
  color: white;
  border: 0px solid transparent;
  border-radius: 7px;
  background-color: #007bff;
  box-sizing: border-box;
  transition: all 0.3s;
}

.error {
  font-size: 150px;
}

.not-found {
  font-size: 28px;
}

@media (max-width : 481px) {
  .error {
    font-size: 80px;
  }

  .not-found {
    font-size: 20px;
  }

  .go-back {
    font-size: 10px;
  }

  .main-content {
    margin: 50px;
    text-align: center;
  }
}
.accordion {
    width: 80%;
    max-width: 1000px;
    margin: 2rem auto;
    padding: 4rem auto;
}

.accordion-item {
    background-color: transparent;
    color: black;
    margin: 1rem 0;
    padding: 1rem;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078);
}

.accordion-item-header {
    font-size: 1.1rem;
    padding: 0.3rem 2rem 0.5rem 0.3rem;
    position: relative;
}
.accordion-item-header-blue {
    font-size: 1.1rem;
    padding: 0.3rem 2rem 0.5rem 0.3rem;
    position: relative;
}
.accordion-item-body {
    transition: all 1s ease-in-out;
}

.accordion-item-body-active {
    border-top: 1px solid rgba(0,0,0,0.1);
}

.accordion-item-header p {
    margin: 0;
}
.accordion-item-header-blue p{
    color: #007bff;
}
.faqButton {
    position: absolute;
    top: 0;
    right: 0;
    transform:rotate(180deg);
    width: 30px;
    height: 30px;
    color: rgba(0,0,0,0.6);
    border: none;
    background-color: transparent;
    transition: transform 0.5s ease-in-out;
}

.faqButton-active {
    position: absolute;
    top: 0;
    right: 0;
    color: #007bff;
    transform: rotate(360deg);
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    transition: transform 0.5s ease-in-out;
}

.accordion-item-body {
    font-size: 0.8rem;
    letter-spacing: 0.005rem;
    padding-top: 10px;
}

.accordion-item-body-content ul{
    list-style-type: none;
    color: black;
}
.accordion-item-body-content ul li:before {
    content: '-';
    position: absolute;
    margin-left: -20px;
}

.accordion-item-body li {
    font-size: 0.95rem;
}

@media (max-width:500px) {
    .accordion-item {
        padding: 0.4rem;
    }
    .accordion-item-header, .accordion-item-header-blue {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        padding-bottom: 5px;
    }
    .accordion-item-header p, .accordion-item-header-blue p {
        font-size: 1rem;
        padding: 0;
        padding-right: 15px;
        width: 90%;
    }
    .accordion-item-header button,.accordion-item-header-blue button {
        height: 20px;
        width: 20px;
    }

    .accordion-item-body-content li {
        font-size: 0.9rem;
    }

    .faqButton {
        transform: rotate(180deg);
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0; 
        padding: 0;
    }

    .faqButton-active {
        transform: rotate(360deg);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0; 
        padding: 0;
    }
}

.book-main {
  padding: 0;
  margin: 0;
  background-color: #edfaf4;
}

.book-main-container {
  height: 87vh;
  display: flex;
  flex-direction: row;
}

.book-left {
  z-index: -1;
  width: 50vw;
}

.book-doc-img {
  height: 100vh;
  position: absolute;
  top: 3rem;
  left: 2rem;
}

.book-asset-img {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 3rem;
}

.book-right {
  display: flex;
  flex-direction: column;
}

.book-right-arrow {
  display: flex;
  margin-top: 3rem;
  margin-right: 2rem;
}

.booking-form {
  display: flex;
  width: 70%;
  justify-content: left !important;
  flex-direction: column;
  display: flex;
}

.book-right-input {
  margin-top: 2rem;
  margin-left: 4rem;
}

.book-arrow {
  font-size: 2rem;
  margin-right: 2rem;
  font-family: "Roboto", sans-serif;
}

.required:after {
  content: " *";
  color: red;
}

.book-right-input .booking-form>input {
  outline: none;
  border: none;
  padding: 0.25rem;
  width: 90%;
  background-color: rgb(227, 244, 250);
  margin-bottom: 1rem;
}

.book-errormsg {
  color: #DC3545;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.80rem;
}
.button-parent {
  --outer-border: 2px solid #1983D8;
  padding: 2px;
  width: -webkit-fit-content;
  width: fit-content;
  border-radius: 9px;
  border-left: var(--outer-border);
  border-right: var(--outer-border);
}

.book-button {
  font-size: 12px;
  color: white;
  border: 0px solid transparent;
  border-radius: 7px;
  background-color: #1983D8;
  box-sizing: content-box;
  transition: all 0.3s;
  padding: 5px var(--pd-btn-booking);
}

.book-button:hover {
  background-color: rgb(33, 130, 160);
}

.booking-blur {
  filter: blur(2px);
}

.fixed {
  height: 100vh;
  overflow: hidden;
}

/* Media Queries---------------------- */
@media (max-width : 900px){
    .book-left{
      display: none;
    }
    .book-right-arrow{
      position: relative;
      margin-top: 0;
      margin-right: 0rem;
      margin: 1rem;
    }
    .book-mobile-header{
      display: flex;
      width: 100%;
      height: 8rem;
      align-items: center;
      background-color: #1983D8;
      color: white;
    }
    .booking-form{
      width: 100%;
      margin: auto;
      justify-content: center !important;
    }
    .book-right-input{
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 2rem auto 0;
      justify-content: center;
    }
    .book-right-input .booking-form> input{
      width: 100%;
      height: 2.5rem;
      margin-bottom: 2rem;
      /* background-color: #edfaf4 ; */
    }
    .book-input-field{
      font-size: 1.25rem;
    }
    .button-parent {
       --outer-border: 3px solid #1983D8;
       width: 100%;
       border-radius: 9px;
       border-left: var(--outer-border);
       border-right: var(--outer-border);
       padding: 2px;
    }
    
    .book-button {
        font-size: var(--fs-home);
        width: 93%;
        display: block;
        margin: 0 auto;
        padding: 10px var(--pd-btn-booking);
        border: 0px solid transparent;
        border-radius: 7px;
        background-color: #1983D8 !important;
        box-sizing: content-box;
        color: white;
        transition: all 0.3s;
      }
    .book-errormsg {
        color: #DC3545;
          margin-top: -2rem !important;
          margin-bottom: 2rem;
          font-size: 0.9rem;
    }
    .book-right-input p {
      font-size: 14px;
    }
}

.Main-Ver-container {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 9999;
  font-family: "Axiforma";
}

.Verification-container {
  display: flex;
  flex-direction: column;
  width: 24rem;
  height: 400px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.ver-sub-container1 {
  text-align: center;
  height: 50%;
  background-color: #1983d8;
}

.ver-btn {
  display: flex;
  float: right;
  height: 20px;
  width: 20px;
  justify-content: center;
  padding-top: 3px;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 50px;
}

.ver-btn:hover {
  background-color: white;
  color: rgb(32, 163, 202);
}

.ver-sub-container2,
.otpSend {
  height: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.mobile-img {
  margin: 20px;
  height: 120px;
  width: 80px;
}

.ver-mini-container {
  margin: -40px;
  background-color: white;
  width: 85%;
  height: 115%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.ver-icon {
  color: black;
  font-size: 555px;
}

.ver-mini-container h2 {
  font-size: 20px;
  margin: 15px;
  text-align: center;
}

.ver-mini-container .ver-message p,
.mobile-errormsg {
  font-size: 0.75rem;
  text-align: center;
  line-height: 12px;
}

.flag-img {
  width: 2.5rem;
  height: 2rem;
  margin-right: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.input {
  margin: 20px;
  display: flex;
  width: 75%;
  align-items: center;
}

.input .code {
  padding: 5px;
  width: 100%;
  background-color: #bbb;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  cursor: pointer;
}
.ver-parent-button {
  --outer-border: 3px solid #1983d8;
  width: -webkit-fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 9px;
  border-left: var(--outer-border);
  border-right: var(--outer-border);
  padding: 1.5px;
}
.ver-button {
  min-width: 3rem;
  font-size: 12px;
  color: white;
  padding: 5px var(--pd-btn-booking);
  border: 0px solid transparent;
  border-radius: 7px;
  background-color: #1983d8 !important;
  box-sizing: content-box;
  transition: all 0.3s;
}

.ver-button:hover {
  background-color: rgb(33, 130, 160);
}

.mobile-errormsg {
  color: #de4535;
  margin-top: -1rem;
}

/* Media Queries---------------- */

@media (max-width: 900px) {
  .Main-Ver-container {
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .Verification-container {
    display: flex;
    height: 380px;
    width: 23rem;
  }
  .mobile-img {
    margin: 20px;
    height: 100px;
    width: 70px;
  }
  .flag-img {
    width: 2.5rem;
    height: 2rem;
  }
  .input {
    margin: 10px 30px;
  }
  .input .code {
    font-size: 14px;
  }
  .mobile-errormsg {
    color: #de4535;
    margin-top: -0.5rem;
  }
}

.dropdown{
    width: 15rem;
    /* width: 17vw; */
    /* margin: 0 auto; */
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    /* align-items: left; */
    flex-direction: column;
    justify-content: left;
}

.dropdown .dropdown-button{
    min-height: 0.5rem;
    padding: 0.25rem;
    padding-left: 0.45rem;
    background-color: rgb(212, 238, 247);
    /* font-weight:; */
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.dropdown-items{
    min-height: 0.5rem;
    max-width: inherit;
    align-items: center;
    word-wrap: break-word;
}

.dropdown .dropdown-content{
    position:relative;
    height: 8rem;
    max-height: 7rem;
    overflow-x:hidden;
    overflow-y:scroll;
    top: 1px;
    left:0;
    cursor: pointer;
    background-color: rgb(212, 238, 247);
    font-weight: 500;
    color: #333;
    width: 100%;
}
.dropdown .dropdown-content::-webkit-scrollbar{
    width: 0.25rem;
}
.dropdown-content::-webkit-scrollbar-thumb{
    background-color: #888;
}
.dropdown-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.dropdown .dropdown-content .dropdown-items {
    display: flex;
    justify-content: space-between;
    padding: 0.40rem;
    cursor: pointer;
    transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-items:hover {
    background: #f5efef;
}
@media (max-width:700px){
    .dropdown .dropdown-button{
        
        min-height: 2.5rem;
        font-size: 1.15rem;
    }
}
.otpVal {
  width: 35px;
  height: 30px;
  margin: 5px;
  border: none;
  background-color: #bbb;
  text-align: center;
  outline: none;
  border-radius: 5px;
  padding: 2px;
}
.vinput-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -webkit-appearance: none;
          appearance: none;
  -moz-appearance: textfield;
}
.mobile-verifyerror {
  font-size: 0.75rem;
  /* background-color: red; */
  text-align: center;
  /* line-height: 15px; */
  color: #de4535;
  margin-top: -1rem;
}
.icon-spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(350deg);
  }
}

.main-container {
  background-color: rgba(255, 255, 255, 0.644);
  /* height: 100vh; */
  /* border: 2px solid black; */
  padding: 1rem;
}

/* Navbar styling */

.dprofile-navbar {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  justify-content: space-between;
  /* background-color: white; */
}
.arrow-container {
  display: flex;
  box-shadow: 1px 1px 8px #cdcccc;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
}
.profile-heading {
  width: 14rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Payout Styling */
.payout {
  /* border: 1px solid rgb(85, 85, 0); */
  padding: 0.5rem;
  margin: 1rem auto;
}

.payout-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid yellow; */
  cursor: pointer;

  width: 100%;
  height: 10rem;
  position: relative;
  background-color: rgb(0, 47, 79);
  /* background-color: rgb(1, 62, 90);  */
  border-radius: 0.5rem;
}
.payout-details > div {
  margin: 0.3rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bolder;
}

.next-arrow {
  /* background-color: #cdcccc; */
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3rem;
  height: 2rem;
}
.animated-arrow {
  /* color: white; */
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.right-arrow1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  /* border: 1px solid red; */
  color: #ffffff6f;
  /* background-color: green; */
  transform: translateX(-5px);
  font-size: 1.5rem;
  /* -webkit-animation-delay: 3s; */
  animation-delay: 3s;
}
.right-arrow2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  /* background-color: red; */
  /* border: 1px solid yellow; */
  color: #ffffffdc;
  transform: translateX(-22px);
  font-size: 1.6rem;
  /* -webkit-animation-delay: 2s; */
  animation-delay: 2s;
}
.right-arrow3 {
  display: flex;
  justify-content: center;
  /* background-color: black; */
  align-items: center;
  width: 32px;
  height: 32px;
  /* border: 1px solid red; */
  color: white;
  transform: translateX(-40px);
  font-size: 1.7rem;
  /* -webkit-animation-delay: 1s; */
  animation-delay: 1s;
}
.sliding {
  /* position: absolute; */
  animation: slider 4s linear infinite;
}

@keyframes slider {
  0% {
    opacity: 0;
    transform: translate(0px);
  }
  20% {
    opacity: 1;
    transform: translate(-2px);
  }
  40% {
    opacity: 1;
    transform: translate(-3px);
  }
  80% {
    opacity: 1;
    transform: translate(3px);
  }
  100% {
    opacity: 0;
    transform: translate(2px);
  }
}

/* Profile info styling */

.doctor-profile {
  padding: 0.5rem;
  /* border: 1px solid green; */
}

label,
input {
  display: block;
  width: 100%;
}
label {
  font-weight: 500;
  font-size: 1.2rem;
}
input {
  padding: 1rem;
  height: 2.5rem;
  outline: none;
  border: 1px solid #e0dede;
  font-size: 1rem;
}

#gender,
#dob {
  width: 50%;

  background-color: white;
}
#dob {
  padding: 0.5rem;
}
.doctor-profile-info > div {
  margin: 1.5rem auto;
}

select {
  height: 2.5rem;
  padding: 0.7rem;
  border: 1px solid #e0dede;
}

.payout-container {
  padding: 1rem;
}
.payout-amount {
  padding: 0.5rem;
  /* border: 1px solid red; */
  width: 100%;
  font-size: 2rem;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.payout-amount > div {
  margin: 0.5rem;
}
.top-left {
  position: absolute !important;
  top: 0;
  left: 0;
}
.pay-btn {
  /* width;
  height: 1re; */
  padding: 0.5rem 1rem;
  color: white;
  font-size: 1rem;
  border-radius: 0.5rem;
  font-weight: bold;

  outline: none;
  border: none;
  background-color: #002f4f;
}
.payout-history {
  padding: 0.5rem;
  /* border: 1px solid green; */
  margin: 1rem auto;
}

.payout-history-card {
  box-shadow: 2px 2px 10px rgb(156, 155, 155);
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 0.5rem;
}

.paid {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
}
.paid > span {
  margin-right: 0.5rem;
  /* border: 1px solid red; */
}

.history-amount {
  font-weight: bold;
  font-size: 2rem;
}
.history-date {
  font-size: 0.9rem;
  font-weight: 600;
  color: #202020ae;
  display: flex;
  justify-content: space-between;
}
.none {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

/* TransactionDetails css ------------------------> */

.transaction-details-container {
  padding: 1rem;
  background-color: #ededed2b;
  min-height: 100vh;
}
.tdetails-header {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.tdetails-header > div {
  margin-right: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.tdetails {
  background-color: white;
  margin: 3rem auto;
  width: 100%;
  /* height: 20rem; */
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 2px 2px 10px rgb(156, 155, 155);
}
.tdetails-info {
  /* border: 1px solid red;  */
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0dede;
  /* padding: 0.5rem; */
}
.tdetails-info .tdetails-info-name {
  font-size: 1.2rem;
  font-weight: 400;
}
.tdetails-info .tdetails-info-amount {
  display: flex;
  align-items: center;

  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
}

.tdetails-value {
  /* border: 1px solid red; */
  width: 100%;
  padding: 1.5rem 1rem;
}
.tdetails-value-heading {
  display: flex;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0dede;
  border-radius: 0.5rem 0.5rem 0rem 0;
}
.tdetails-value-content {
  border: 1px solid #e0dede;
  border-top: none;
  border-radius: 0rem 0rem 0.5rem 0.5rem;

  /* padding: 1rem; */
}
.tdetails-value-content > div {
  padding: 1rem;
}
.transaction-id-name {
  font-weight: 500;
}
.transaction-id-value {
  color: #202020ae;
  width: 100%;
  /* border: 1px solid green; */
  word-break: break-all;
}

.outer-container{ margin-left: 15%; margin-right:10%; }
.navfix {
    position: -webkit-sticky;
    position: sticky;
    top:0;
    left:0;
    height: 10%;
    padding: 0.8%;
    padding-bottom: 13%;
    color: #3a3a3a;
}
.navfix>div {
    float: left;
}
.nav-links{
    margin-left: 4%;
    margin-top: 4%;
}
.img-container {
    width: 50%;
    margin: 1%;
    float: left
}
.img-style{
    width:180px;
    height:180px;
}
.bold {
    font-weight: bold;
}
.times-font{
    position:relative;
    height:92%;
    font-family: "Times New Roman", Times, serif;
}
.sub-heading{
    font-weight: 600;
    font-size:75px;
    transform: scale(1, 1.1);
}
.top-heading{
    margin-top:8%;
}
.tab-heading{
    margin-left: 120px;
}
.green-button{
    background-color:#2E8B57;
    font-size: 2.5rem;
    border-radius: .95rem;
    color:aliceblue;
}
.sub-container{
    margin-left: 38%;
}
.sub-container1{
    margin-left: 5%;
}

.fw2{
    font-weight: 350;
    font-size: 70px;
}
.fw1{
    font-weight: 500;
}
.covid-box {
    width: 70%;
    border: 2px solid #2E8B57;
    font-weight: 610;
    font-size: 30px;
}
.covid-box2{
    width: 70%;
    /* border: 2px solid #2E8B57; */
    border: 2px white;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
}

.border-green{
    border: 2px solid #2E8B57;
}
.image-row{margin-left: 2px; margin-right: 5px; margin-top:10px; margin-bottom: 5px;}

.client-box{
    width: 70%;
    height:400px;
    border: 2px solid #2E8B57;
    padding: 5px;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
}
.footer-box{
    width: 70%;
    height:150px;
    background-color: rgba(152, 251, 152, 0.3);
    padding: 5px;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
}
.card-spec{
    width:330px;
    height:350px;
}

/* font-weight */
.fw-100{font-weight:100} .fw-200{font-weight:200} .fw-300{font-weight:300} .fw-400{font-weight: 400;} .fw-500{font-weight: 500;} .fw-600{font-weight: 600;} .fw-700{font-weight: 700;} 
/* font-size */
.fs-5{font-size:5px} .fs-10{font-size:10px} .fs-15{font-size: 15px;} .fs-20{font-size: 20px;} .fs-25{font-size: 25px;} .fs-30{font-size: 30px;}.fs-35{font-size: 35px;} .fs-40{font-size: 40px;} .fs-45{font-size: 45px;} .fs-50{font-size: 50px;}

/* margins */
.mr-0{ margin-right:0px;}.mr-5{ margin-right:5px;} .mr-10{ margin-right:10px;} .mr-15{ margin-right:15px;} .mr-20{ margin-right:20px;}.mr-25{ margin-right:25px;} .mr-30{ margin-right:30px;}
.ml-0{ margin-left:0px;}.ml-5{ margin-left:5px;} .ml-10{ margin-left:10px;} .ml-15{ margin-left:15px; } .ml-20{ margin-left:20px;} .ml-25{ margin-left:25px;} .ml-30{ margin-left:30px;}
.ml-2{margin-left: 2px;}.ml-4{margin-left: 4px;}
.ml-400{margin-left: 400px;}
.mt-0{ margin-top:0px;}.mt-5{ margin-top:5px;} .mt-10{ margin-top:10px;} .mt-15{ margin-top:15px;} .mt-20{ margin-top:20px;}.mt-25{ margin-top:25px;} .mt-30{ margin-top:30px;}
.mb-0{ margin-bottom:0px;}.mb-5{ margin-bottom:5px;} .mb-10{ margin-bottom:10px;} .mb-15{ margin-bottom:15px; } .mb-20{ margin-bottom:20px;} .mb-25{ margin-bottom:25px;} .mb-30{ margin-bottom:30px;}

.form-label{
    font-size:20px;
}
.form-control{
    width:100%;
    color: black;
    display:block;
    background:transparent;
}
@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-SemiBold.6534d617.eot);
    src: local('Axiforma SemiBold'), local('Axiforma-SemiBold'),
        url(/static/media/Axiforma-SemiBold.6534d617.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-SemiBold.bbe982e4.woff2) format('woff2'),
        url(/static/media/Axiforma-SemiBold.6cd324e9.woff) format('woff'),
        url(/static/media/Axiforma-SemiBold.30aeae71.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Thin.6e7f0e54.eot);
    src: local('Axiforma Thin'), local('Axiforma-Thin'),
        url(/static/media/Axiforma-Thin.6e7f0e54.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Thin.1ec8830a.woff2) format('woff2'),
        url(/static/media/Axiforma-Thin.ed013fb1.woff) format('woff'),
        url(/static/media/Axiforma-Thin.8b9bce7c.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Regular.b11f3c9e.eot);
    src: local('Axiforma Regular'), local('Axiforma-Regular'),
        url(/static/media/Axiforma-Regular.b11f3c9e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Regular.7f1cf476.woff2) format('woff2'),
        url(/static/media/Axiforma-Regular.2b258adf.woff) format('woff'),
        url(/static/media/Axiforma-Regular.c4f8a961.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-MediumItalic.461d9cd1.eot);
    src: local('Axiforma Medium Italic'), local('Axiforma-MediumItalic'),
        url(/static/media/Axiforma-MediumItalic.461d9cd1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-MediumItalic.229dcad6.woff2) format('woff2'),
        url(/static/media/Axiforma-MediumItalic.95b8e027.woff) format('woff'),
        url(/static/media/Axiforma-MediumItalic.19a9baa4.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Black.244b6984.eot);
    src: local('Axiforma Black'), local('Axiforma-Black'),
        url(/static/media/Axiforma-Black.244b6984.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Black.304fdea0.woff2) format('woff2'),
        url(/static/media/Axiforma-Black.992ceed2.woff) format('woff'),
        url(/static/media/Axiforma-Black.48cabd4c.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-BlackItalic.2b9b9ca5.eot);
    src: local('Axiforma Black Italic'), local('Axiforma-BlackItalic'),
        url(/static/media/Axiforma-BlackItalic.2b9b9ca5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-BlackItalic.a32c11a3.woff2) format('woff2'),
        url(/static/media/Axiforma-BlackItalic.d7673cb8.woff) format('woff'),
        url(/static/media/Axiforma-BlackItalic.99f62680.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Heavy.edf09869.eot);
    src: local('Axiforma Heavy'), local('Axiforma-Heavy'),
        url(/static/media/Axiforma-Heavy.edf09869.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Heavy.2e1c4a81.woff2) format('woff2'),
        url(/static/media/Axiforma-Heavy.1d21f0e7.woff) format('woff'),
        url(/static/media/Axiforma-Heavy.d25fea7e.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Medium.a41470f5.eot);
    src: local('Axiforma Medium'), local('Axiforma-Medium'),
        url(/static/media/Axiforma-Medium.a41470f5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Medium.1470f645.woff2) format('woff2'),
        url(/static/media/Axiforma-Medium.27f762a5.woff) format('woff'),
        url(/static/media/Axiforma-Medium.230a7590.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-BoldItalic.5f90be97.eot);
    src: local('Axiforma Bold Italic'), local('Axiforma-BoldItalic'),
        url(/static/media/Axiforma-BoldItalic.5f90be97.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-BoldItalic.ece88e36.woff2) format('woff2'),
        url(/static/media/Axiforma-BoldItalic.6be20694.woff) format('woff'),
        url(/static/media/Axiforma-BoldItalic.adfb2c8c.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-ExtraBoldItalic.3e4db1d5.eot);
    src: local('Axiforma ExtraBold Italic'), local('Axiforma-ExtraBoldItalic'),
        url(/static/media/Axiforma-ExtraBoldItalic.3e4db1d5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-ExtraBoldItalic.64172490.woff2) format('woff2'),
        url(/static/media/Axiforma-ExtraBoldItalic.d5a37a5b.woff) format('woff'),
        url(/static/media/Axiforma-ExtraBoldItalic.b3bc5a0f.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Book.678853dd.eot);
    src: local('Axiforma Book'), local('Axiforma-Book'),
        url(/static/media/Axiforma-Book.678853dd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Book.08819a58.woff2) format('woff2'),
        url(/static/media/Axiforma-Book.facdd85e.woff) format('woff'),
        url(/static/media/Axiforma-Book.9b01bb5c.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Light.5fc12a84.eot);
    src: local('Axiforma Light'), local('Axiforma-Light'),
        url(/static/media/Axiforma-Light.5fc12a84.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Light.2eda7463.woff2) format('woff2'),
        url(/static/media/Axiforma-Light.bbb27e30.woff) format('woff'),
        url(/static/media/Axiforma-Light.fe89ffb1.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-ThinItalic.14b055b5.eot);
    src: local('Axiforma Thin Italic'), local('Axiforma-ThinItalic'),
        url(/static/media/Axiforma-ThinItalic.14b055b5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-ThinItalic.3d12efa9.woff2) format('woff2'),
        url(/static/media/Axiforma-ThinItalic.78b33a85.woff) format('woff'),
        url(/static/media/Axiforma-ThinItalic.db6d8e41.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Bold.fb8f48a9.eot);
    src: local('Axiforma Bold'), local('Axiforma-Bold'),
        url(/static/media/Axiforma-Bold.fb8f48a9.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Bold.a3329a33.woff2) format('woff2'),
        url(/static/media/Axiforma-Bold.a5763688.woff) format('woff'),
        url(/static/media/Axiforma-Bold.179a2453.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-LightItalic.9c472e31.eot);
    src: local('Axiforma Light Italic'), local('Axiforma-LightItalic'),
        url(/static/media/Axiforma-LightItalic.9c472e31.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-LightItalic.23e422fe.woff2) format('woff2'),
        url(/static/media/Axiforma-LightItalic.34b37b94.woff) format('woff'),
        url(/static/media/Axiforma-LightItalic.1e8d8385.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-SemiBoldItalic.0907fa89.eot);
    src: local('Axiforma SemiBold Italic'), local('Axiforma-SemiBoldItalic'),
        url(/static/media/Axiforma-SemiBoldItalic.0907fa89.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-SemiBoldItalic.761ae083.woff2) format('woff2'),
        url(/static/media/Axiforma-SemiBoldItalic.2fa3087f.woff) format('woff'),
        url(/static/media/Axiforma-SemiBoldItalic.db6a231b.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-Italic.68790542.eot);
    src: local('Axiforma Italic'), local('Axiforma-Italic'),
        url(/static/media/Axiforma-Italic.68790542.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-Italic.d601f3e8.woff2) format('woff2'),
        url(/static/media/Axiforma-Italic.1b111652.woff) format('woff'),
        url(/static/media/Axiforma-Italic.d91acd2e.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-BookItalic.7737fcd2.eot);
    src: local('Axiforma Book Italic'), local('Axiforma-BookItalic'),
        url(/static/media/Axiforma-BookItalic.7737fcd2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-BookItalic.18aadafc.woff2) format('woff2'),
        url(/static/media/Axiforma-BookItalic.18d83adc.woff) format('woff'),
        url(/static/media/Axiforma-BookItalic.1ed0dc65.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-ExtraBold.7e1d0a91.eot);
    src: local('Axiforma ExtraBold'), local('Axiforma-ExtraBold'),
        url(/static/media/Axiforma-ExtraBold.7e1d0a91.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-ExtraBold.7be4f765.woff2) format('woff2'),
        url(/static/media/Axiforma-ExtraBold.6e089bf6.woff) format('woff'),
        url(/static/media/Axiforma-ExtraBold.2aa236fe.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Axiforma';
    src: url(/static/media/Axiforma-HeavyItalic.774ef1f2.eot);
    src: local('Axiforma Heavy Italic'), local('Axiforma-HeavyItalic'),
        url(/static/media/Axiforma-HeavyItalic.774ef1f2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Axiforma-HeavyItalic.0d262865.woff2) format('woff2'),
        url(/static/media/Axiforma-HeavyItalic.218c09af.woff) format('woff'),
        url(/static/media/Axiforma-HeavyItalic.bb4b31d1.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}


