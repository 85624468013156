.dropdown{
    width: 15rem;
    /* width: 17vw; */
    /* margin: 0 auto; */
    user-select: none;
    display: flex;
    /* align-items: left; */
    flex-direction: column;
    justify-content: left;
}

.dropdown .dropdown-button{
    min-height: 0.5rem;
    padding: 0.25rem;
    padding-left: 0.45rem;
    background-color: rgb(212, 238, 247);
    /* font-weight:; */
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.dropdown-items{
    min-height: 0.5rem;
    max-width: inherit;
    align-items: center;
    word-wrap: break-word;
}

.dropdown .dropdown-content{
    position:relative;
    height: 8rem;
    max-height: 7rem;
    overflow-x:hidden;
    overflow-y:scroll;
    top: 1px;
    left:0;
    cursor: pointer;
    background-color: rgb(212, 238, 247);
    font-weight: 500;
    color: #333;
    width: 100%;
}
.dropdown .dropdown-content::-webkit-scrollbar{
    width: 0.25rem;
}
.dropdown-content::-webkit-scrollbar-thumb{
    background-color: #888;
}
.dropdown-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.dropdown .dropdown-content .dropdown-items {
    display: flex;
    justify-content: space-between;
    padding: 0.40rem;
    cursor: pointer;
    transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-items:hover {
    background: #f5efef;
}
@media (max-width:700px){
    .dropdown .dropdown-button{
        
        min-height: 2.5rem;
        font-size: 1.15rem;
    }
}