.outer-container{ margin-left: 15%; margin-right:10%; }
.navfix {
    position: sticky;
    top:0;
    left:0;
    height: 10%;
    padding: 0.8%;
    padding-bottom: 13%;
    color: #3a3a3a;
}
.navfix>div {
    float: left;
}
.nav-links{
    margin-left: 4%;
    margin-top: 4%;
}
.img-container {
    width: 50%;
    margin: 1%;
    float: left
}
.img-style{
    width:180px;
    height:180px;
}
.bold {
    font-weight: bold;
}
.times-font{
    position:relative;
    height:92%;
    font-family: "Times New Roman", Times, serif;
}
.sub-heading{
    font-weight: 600;
    font-size:75px;
    transform: scale(1, 1.1);
}
.top-heading{
    margin-top:8%;
}
.tab-heading{
    margin-left: 120px;
}
.green-button{
    background-color:#2E8B57;
    font-size: 2.5rem;
    border-radius: .95rem;
    color:aliceblue;
}
.sub-container{
    margin-left: 38%;
}
.sub-container1{
    margin-left: 5%;
}

.fw2{
    font-weight: 350;
    font-size: 70px;
}
.fw1{
    font-weight: 500;
}
.covid-box {
    width: 70%;
    border: 2px solid #2E8B57;
    font-weight: 610;
    font-size: 30px;
}
.covid-box2{
    width: 70%;
    /* border: 2px solid #2E8B57; */
    border: 2px white;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
}

.border-green{
    border: 2px solid #2E8B57;
}
.image-row{margin-left: 2px; margin-right: 5px; margin-top:10px; margin-bottom: 5px;}

.client-box{
    width: 70%;
    height:400px;
    border: 2px solid #2E8B57;
    padding: 5px;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
}
.footer-box{
    width: 70%;
    height:150px;
    background-color: rgba(152, 251, 152, 0.3);
    padding: 5px;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
}
.card-spec{
    width:330px;
    height:350px;
}

/* font-weight */
.fw-100{font-weight:100} .fw-200{font-weight:200} .fw-300{font-weight:300} .fw-400{font-weight: 400;} .fw-500{font-weight: 500;} .fw-600{font-weight: 600;} .fw-700{font-weight: 700;} 
/* font-size */
.fs-5{font-size:5px} .fs-10{font-size:10px} .fs-15{font-size: 15px;} .fs-20{font-size: 20px;} .fs-25{font-size: 25px;} .fs-30{font-size: 30px;}.fs-35{font-size: 35px;} .fs-40{font-size: 40px;} .fs-45{font-size: 45px;} .fs-50{font-size: 50px;}

/* margins */
.mr-0{ margin-right:0px;}.mr-5{ margin-right:5px;} .mr-10{ margin-right:10px;} .mr-15{ margin-right:15px;} .mr-20{ margin-right:20px;}.mr-25{ margin-right:25px;} .mr-30{ margin-right:30px;}
.ml-0{ margin-left:0px;}.ml-5{ margin-left:5px;} .ml-10{ margin-left:10px;} .ml-15{ margin-left:15px; } .ml-20{ margin-left:20px;} .ml-25{ margin-left:25px;} .ml-30{ margin-left:30px;}
.ml-2{margin-left: 2px;}.ml-4{margin-left: 4px;}
.ml-400{margin-left: 400px;}
.mt-0{ margin-top:0px;}.mt-5{ margin-top:5px;} .mt-10{ margin-top:10px;} .mt-15{ margin-top:15px;} .mt-20{ margin-top:20px;}.mt-25{ margin-top:25px;} .mt-30{ margin-top:30px;}
.mb-0{ margin-bottom:0px;}.mb-5{ margin-bottom:5px;} .mb-10{ margin-bottom:10px;} .mb-15{ margin-bottom:15px; } .mb-20{ margin-bottom:20px;} .mb-25{ margin-bottom:25px;} .mb-30{ margin-bottom:30px;}

.form-label{
    font-size:20px;
}
.form-control{
    width:100%;
    color: black;
    display:block;
    background:transparent;
}