.footer-main-container {
    background-color: #e7f3ff;
    position: relative;
    top: 0;
    bottom: 0;
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 280px;
    overflow: hidden;
    z-index: 999;
}

.footer-main-container1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 30px 0px 0 60px;
}

.footer-sub-container {
    display: flex;
    justify-content: space-between;
}

.footer-max-container {
    display: flex;
    flex-direction: column;
}
.footer-right-container {
    margin-top: 30px;
}
.footer-list {
    font-size: 14px;
    display: flex;
    color: rgb(73, 71, 71);
}
.footer-right-list {
    font-size: 12px;
    display: flex;
    color: rgb(73, 71, 71);
    margin: 8px 0 8px 0;
}
.footer-sub-head {
    font-size: 15px;
}
.footer-left-container a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.footer-left-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.footer-right-container a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.footer-max-container h3 {
    margin-bottom: 20px;
}
.footer-max-container h3 a {
    color: black;
    text-decoration: none;
    font-size: smaller;
}
.footer-left-container a:hover {
    color: rgb(158, 153, 153);
}
.footer-right-container a:hover {
    color: rgb(158, 153, 153);
}

.footer-mini-container {
    flex-direction: column;
    display: flex;

    /* border: 1px solid red; */
    flex: 2;
}

.footers-icons>a {
    color: white;
    background-color: #CC9B55;
    height: 42px;
    width: 42px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.footers-icons {
    margin: 0;
    display: flex;
    gap: 1rem;
}
.footers-icons a:hover {
    color: #1983D8;
}
