.confirm-details {
    min-height: 70vh;
    margin: auto;
    padding: 3rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    width: 85%;
}

.icon-spinner{
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
  
    100% {
        transform: rotate(350deg);
    }
  }

.confirm-details-element {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.confirm-details-element-label {
    font-size: 1.2rem;
    margin: 0;
    padding-left: 0.3rem;
    font-weight: 600;
}

.confirm-details-element-text {
    border: 1px solid #c3e0ff;
    background-color: #ebf4fe;
    font-size: 1.1rem;
    line-height: 2.5rem;
    padding-left: 0.3rem;
    color: #71777b;
}

.confirm-details-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.booking-amt-label {
    font-size: 1.1rem;
    margin: 2rem 1rem 2rem 0;
}

.booknow-parent {
    width: 100%;
    border-left: 2px solid #007bff;
    border-right: 2px solid #007bff;
    padding: 2px;
    border-radius: 9px;
}

.booknow-parent button {
    width: 100%;
}

@media(min-width : 481px) {
    .confirm-details {
        width: 60%;
        max-width: 30rem;
    }

    .booknow-parent {
        width: fit-content;
    }

    .booknow-parent button {
        width: fit-content;
    }
}
