.Main-Ver-container {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 9999;
  font-family: "Axiforma";
}

.Verification-container {
  display: flex;
  flex-direction: column;
  width: 24rem;
  height: 400px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.ver-sub-container1 {
  text-align: center;
  height: 50%;
  background-color: #1983d8;
}

.ver-btn {
  display: flex;
  float: right;
  height: 20px;
  width: 20px;
  justify-content: center;
  padding-top: 3px;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 50px;
}

.ver-btn:hover {
  background-color: white;
  color: rgb(32, 163, 202);
}

.ver-sub-container2,
.otpSend {
  height: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.mobile-img {
  margin: 20px;
  height: 120px;
  width: 80px;
}

.ver-mini-container {
  margin: -40px;
  background-color: white;
  width: 85%;
  height: 115%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.ver-icon {
  color: black;
  font-size: 555px;
}

.ver-mini-container h2 {
  font-size: 20px;
  margin: 15px;
  text-align: center;
}

.ver-mini-container .ver-message p,
.mobile-errormsg {
  font-size: 0.75rem;
  text-align: center;
  line-height: 12px;
}

.flag-img {
  width: 2.5rem;
  height: 2rem;
  margin-right: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.input {
  margin: 20px;
  display: flex;
  width: 75%;
  align-items: center;
}

.input .code {
  padding: 5px;
  width: 100%;
  background-color: #bbb;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  cursor: pointer;
}
.ver-parent-button {
  --outer-border: 3px solid #1983d8;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 9px;
  border-left: var(--outer-border);
  border-right: var(--outer-border);
  padding: 1.5px;
}
.ver-button {
  min-width: 3rem;
  font-size: 12px;
  color: white;
  padding: 5px var(--pd-btn-booking);
  border: 0px solid transparent;
  border-radius: 7px;
  background-color: #1983d8 !important;
  box-sizing: content-box;
  transition: all 0.3s;
}

.ver-button:hover {
  background-color: rgb(33, 130, 160);
}

.mobile-errormsg {
  color: #de4535;
  margin-top: -1rem;
}

/* Media Queries---------------- */

@media (max-width: 900px) {
  .Main-Ver-container {
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .Verification-container {
    display: flex;
    height: 380px;
    width: 23rem;
  }
  .mobile-img {
    margin: 20px;
    height: 100px;
    width: 70px;
  }
  .flag-img {
    width: 2.5rem;
    height: 2rem;
  }
  .input {
    margin: 10px 30px;
  }
  .input .code {
    font-size: 14px;
  }
  .mobile-errormsg {
    color: #de4535;
    margin-top: -0.5rem;
  }
}
