.patient-testimonial-carousel .slick-dots li.slick-active button:before {
    color: #2082e4;
}

.patient-testimonial-carousel .slick-dots {
    position: relative;
}
.patient-testimonial-carousel {
    width: 85%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 630px) {
    .patient-testimonial-carousel {
        width: 100%;
    }
}
