@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
@import url("https://www.gfxfile.com/Wmh/Axiforma.rar");

.book-main {
  padding: 0;
  margin: 0;
  background-color: #edfaf4;
}

.book-main-container {
  height: 87vh;
  display: flex;
  flex-direction: row;
}

.book-left {
  z-index: -1;
  width: 50vw;
}

.book-doc-img {
  height: 100vh;
  position: absolute;
  top: 3rem;
  left: 2rem;
}

.book-asset-img {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 3rem;
}

.book-right {
  display: flex;
  flex-direction: column;
}

.book-right-arrow {
  display: flex;
  margin-top: 3rem;
  margin-right: 2rem;
}

.booking-form {
  display: flex;
  width: 70%;
  justify-content: left !important;
  flex-direction: column;
  display: flex;
}

.book-right-input {
  margin-top: 2rem;
  margin-left: 4rem;
}

.book-arrow {
  font-size: 2rem;
  margin-right: 2rem;
  font-family: "Roboto", sans-serif;
}

.required:after {
  content: " *";
  color: red;
}

.book-right-input .booking-form>input {
  outline: none;
  border: none;
  padding: 0.25rem;
  width: 90%;
  background-color: rgb(227, 244, 250);
  margin-bottom: 1rem;
}

.book-errormsg {
  color: #DC3545;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.80rem;
}
.button-parent {
  --outer-border: 2px solid #1983D8;
  padding: 2px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 9px;
  border-left: var(--outer-border);
  border-right: var(--outer-border);
}

.book-button {
  font-size: 12px;
  color: white;
  border: 0px solid transparent;
  border-radius: 7px;
  background-color: #1983D8;
  box-sizing: content-box;
  transition: all 0.3s;
  padding: 5px var(--pd-btn-booking);
}

.book-button:hover {
  background-color: rgb(33, 130, 160);
}

.booking-blur {
  filter: blur(2px);
}

.fixed {
  height: 100vh;
  overflow: hidden;
}

/* Media Queries---------------------- */
@media (max-width : 900px){
    .book-left{
      display: none;
    }
    .book-right-arrow{
      position: relative;
      margin-top: 0;
      margin-right: 0rem;
      margin: 1rem;
    }
    .book-mobile-header{
      display: flex;
      width: 100%;
      height: 8rem;
      align-items: center;
      background-color: #1983D8;
      color: white;
    }
    .booking-form{
      width: 100%;
      margin: auto;
      justify-content: center !important;
    }
    .book-right-input{
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 2rem auto 0;
      justify-content: center;
    }
    .book-right-input .booking-form> input{
      width: 100%;
      height: 2.5rem;
      margin-bottom: 2rem;
      /* background-color: #edfaf4 ; */
    }
    .book-input-field{
      font-size: 1.25rem;
    }
    .button-parent {
       --outer-border: 3px solid #1983D8;
       width: 100%;
       border-radius: 9px;
       border-left: var(--outer-border);
       border-right: var(--outer-border);
       padding: 2px;
    }
    
    .book-button {
        font-size: var(--fs-home);
        width: 93%;
        display: block;
        margin: 0 auto;
        padding: 10px var(--pd-btn-booking);
        border: 0px solid transparent;
        border-radius: 7px;
        background-color: #1983D8 !important;
        box-sizing: content-box;
        color: white;
        transition: all 0.3s;
      }
    .book-errormsg {
        color: #DC3545;
          margin-top: -2rem !important;
          margin-bottom: 2rem;
          font-size: 0.9rem;
    }
    .book-right-input p {
      font-size: 14px;
    }
}
