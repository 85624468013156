.doctor-container{
    display:flex;
    justify-content: center;
}
.doctor-card{
    height: 20rem;
    width: 17rem;
    /* margin-bottom: 3rem; */
    /* padding: 1rem; */
    overflow: hidden;
    /* border: 2px solid black; */
    border-radius: 0.5rem;
}
.doctor-card img{
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    width: inherit;
    border-radius: 0.5rem;
    object-fit: cover;
}
.doctor-intro{
    height: 2.5rem;
    padding: 0.5rem;
    width: 17rem;
    position: absolute;
    bottom: 0rem;
    background: rgba(89, 102, 101, 0.5);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: white;
    transition: .5s;
    overflow: hidden;
}
.doctor-intro h1{
    text-align: center;
    font-size: 1.2rem;
    /* color: black; */
}
.doctor-intro p{
    font-size: 1rem;
    text-align: center;
    visibility:hidden;
    opacity: 0;
    /* color: black; */
}
.doctor-card:hover{
    cursor: pointer;
}
.doctor-card:hover .doctor-intro{
    height: 6rem;
    bottom: 0rem;
}
.doctor-card:hover p{
    opacity: 1;
    visibility: visible;
}
.doctor-card:hover img{
    /* transform: scale(1.1); */
    transition: 0.5s;
}
@media(max-width : 616px) {
    
}
@media(max-width : 1300px) and (min-width:6616px) {

    .doctor-card {
        margin: 3.2rem;
        width: 14rem;
    }

    .doctor-intro {
        width: 13.5rem;
    }

    .doctor-intro h1 {
        font-size: 1.1rem;
    }

    .doctor-card img {
        width: inherit;
        object-fit: cover;
    }
}