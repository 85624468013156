:root {
    --fs-section-heading: min(calc(1.3rem + 0.5vw), 1.9rem);
    --fs-section-objective: min(calc(0.9rem + 0.2vw), 1.2rem);
}

* {
    font-family: 'Axiforma', 'sans-serif';
}


body {
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

.main-content-2 {
    background-color: #f6f6f6;
    margin: 0;
}

.header {
    padding: 50px 3vw 25px 3vw;
    text-align: center;
    color: #3b3b3b;
}

.header-content {
    font-size: var(--fs-section-heading);
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 8px;
}

.header-body {
    font-size: var(--fs-section-objective);
    margin-bottom: 12px;
}
.header-less-margin {
    margin-top: 0;
}
.underline {
    /* position: relative; */
    width: 100%;
    margin: 0 auto;
    margin: 2px;
}

.underline::after {
    position: absolute;
    content: "";
    height: 1.5px;
    width: 10%;
    background-color: #dda15e;
    left: 45%;
}

.arrow {
    height: 20px;
    width: 32px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.procedure-cards {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.procedure-cards-mob {
    display: none;
}

.desktop-footer {
    display: none;
}

.mobile-footer {
    display: block;
}

@media(min-width : 481px) {
    .procedure-cards {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media(min-width : 900px) {
    .desktop-footer {
        display: block;
    }
    .mobile-footer {
        display: none;
    }
}

@media screen and (max-width: 630px) {
    .procedure-cards {
        display: none;
    }
    .procedure-cards-mob {
        display: block;
    }
}
