.payout-container {
  padding: 1rem;
}
.payout-amount {
  padding: 0.5rem;
  /* border: 1px solid red; */
  width: 100%;
  font-size: 2rem;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.payout-amount > div {
  margin: 0.5rem;
}
.top-left {
  position: absolute !important;
  top: 0;
  left: 0;
}
.pay-btn {
  /* width;
  height: 1re; */
  padding: 0.5rem 1rem;
  color: white;
  font-size: 1rem;
  border-radius: 0.5rem;
  font-weight: bold;

  outline: none;
  border: none;
  background-color: #002f4f;
}
.payout-history {
  padding: 0.5rem;
  /* border: 1px solid green; */
  margin: 1rem auto;
}

.payout-history-card {
  box-shadow: 2px 2px 10px rgb(156, 155, 155);
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 0.5rem;
}

.paid {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
}
.paid > span {
  margin-right: 0.5rem;
  /* border: 1px solid red; */
}

.history-amount {
  font-weight: bold;
  font-size: 2rem;
}
.history-date {
  font-size: 0.9rem;
  font-weight: 600;
  color: #202020ae;
  display: flex;
  justify-content: space-between;
}
.none {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

/* TransactionDetails css ------------------------> */

.transaction-details-container {
  padding: 1rem;
  background-color: #ededed2b;
  min-height: 100vh;
}
.tdetails-header {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.tdetails-header > div {
  margin-right: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.tdetails {
  background-color: white;
  margin: 3rem auto;
  width: 100%;
  /* height: 20rem; */
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 2px 2px 10px rgb(156, 155, 155);
}
.tdetails-info {
  /* border: 1px solid red;  */
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0dede;
  /* padding: 0.5rem; */
}
.tdetails-info .tdetails-info-name {
  font-size: 1.2rem;
  font-weight: 400;
}
.tdetails-info .tdetails-info-amount {
  display: flex;
  align-items: center;

  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
}

.tdetails-value {
  /* border: 1px solid red; */
  width: 100%;
  padding: 1.5rem 1rem;
}
.tdetails-value-heading {
  display: flex;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0dede;
  border-radius: 0.5rem 0.5rem 0rem 0;
}
.tdetails-value-content {
  border: 1px solid #e0dede;
  border-top: none;
  border-radius: 0rem 0rem 0.5rem 0.5rem;

  /* padding: 1rem; */
}
.tdetails-value-content > div {
  padding: 1rem;
}
.transaction-id-name {
  font-weight: 500;
}
.transaction-id-value {
  color: #202020ae;
  width: 100%;
  /* border: 1px solid green; */
  word-break: break-all;
}
