.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url('https://i.pinimg.com/originals/69/3a/6a/693a6ac031d56ed140365a02be40dd2a.jpg');
  background-size: cover;
  background-position: center center;
}

.Logo {
  margin-left: 38%;
}

.btn {
  background-color: #12AD2B;

}

.btn:hover {
  background-color: #fff;
  color: black;
  border: 2px solid #12AD2B;
}

.forgot {
  color: black;
}
