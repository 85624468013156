.mfooter-main-container {
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.mfooter-sub-container {
    width: 100%;
}

.mfooter-container {
    color: white;
    text-align: center;
    background-color: #389AD1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mfooter-header {
    margin-top: 4px;
    width: 50%;
}

.mfooter-header h1 {
    font-weight: bolder;
    font-size: 40px;
}

.mfooter-header p {
    font-size: 13px;
}

a:hover {
    color: #bbb;
}

.mfooter-lists {
    font-size: 13px;
    width: 45%;
    border-top: 1px solid white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 2px;
}

.mfooter-container a {
    text-decoration: none;
    color: white;

}

.mfooter-social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    width: 15rem;
    border-bottom: 1px solid rgb(168, 166, 166);
}

.mfooter-social>a {
    color: white;
    background-color: #CC9B55;
    align-items: center;
    margin: 0.5rem;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    /* padding-bottom: 3px; */
    /* padding: 4px 4px 1px 1px; */
    border-radius: 50%;

}

.mfooter-copyright {
    text-align: center;
    font-size: 11px;
    color: rgb(124, 124, 124);
    margin-top: 10px;
}
