.slick-dots li.slick-active button:before {
    color: #bc6c25;
}

.slick-prev:before,
.slick-prev:after,
.slick-next:before,
.slick-next:after {
    color: #bc6c25;
}

.condition-carousel {
    width: 90%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 630px) {
    .condition-carousel {
        width: 100%;
    }
}
