.choose-us {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

.choose-us-content {
    margin: 2rem 0;
}

.choose-us-content img {
    height: 125px;
    width: 125px;
}

@media (max-width : 480px) {
    .choose-us-content img {
        height: 80px;
        width: 80px;
    }

    .choose-us-content h5 {
        font-size: 0.9rem;
    }
}

@media (min-width : 481px) and (max-width : 768px) {
    .choose-us-content img {
        height: 100px;
        width: 100px;
    }

    .choose-us-content h5 {
        font-size: 1rem;
    }
}
