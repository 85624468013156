.infoCarousel{
  width: 82%;
  margin: auto;
  margin-top: 1rem;
}
.infoCarousel .slick-slide:not(.slick-center) {
  /* opacity: 0.7; */
}
.infoCarousel .slick-slide .info-card-body-container {
  opacity: 1;
} 
.infoCarousel .slick-dots {
  position: relative;
} 

.infoCarousel .slick-dots li.slick-active button:before {
  color: #007bff;
}
.slick-dots li button:focus:before {
  opacity: 0.25;
}

.slick-dots li.slick-active button:focus:before,
.slick-dots li button:hover:before, .slick-dots li button:active:before {
  opacity: 1;
}
.infoCarousel .slick-dots li button:hover:after {
  color: #007bff;
} 
@media(max-width: 1025px) {

  .infoCarousel{
    width: 100%;
  }

  .infoCarousel .slick-center .info-card-body-container {
    animation: animatebottom 1s 1s forwards ease-in-out;
  }
    .slick-dots li button:focus:before {
      opacity: 0.25;
    }
  
    .slick-dots li.slick-active button:focus:before,
    .slick-dots li button:hover:before,
    .slick-dots li button:active:before {
      opacity: 1;
    }
  @keyframes animatebottom {
    from {
      bottom: -100px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }

}
