.service-card {
    width: min(calc(260px + 8vw), 320px);
    height: calc(300px - 1.5vw);
    overflow: hidden;
    margin: 1rem;
    position: relative;
    border-radius: 0.4rem;
    padding: 0.8rem;
}

.service-card-img-container {
    width: 100%;
    height: 80%;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
}

.service-card-img {
    width: 100%;
    height: 100%;
}

.service-card-body {
    font-size: clamp(0.95rem, calc(0.95rem + 0.3vw), 1rem);
    font-weight: 500;
    text-align: center;
    max-height: fit-content;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
}

.service-card:nth-of-type(4) img {
    width: 80%;
}

@media(max-width: 1200px) {
    .arrow {
        display: none;
    }
}

@media (min-width: 769px) {
    .service-card {
        width: max(calc(280px - 2vw), 240px);
        height: calc(270px - 1.8vw);
        margin: 0.5rem;
        align-items: baseline;
        transition: all 0.3s ease-in-out;
    }
}

@media screen and (max-width: 630px) {
    .service-card {
        margin-left: auto;
        margin-right: auto;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    }
}