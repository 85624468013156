.bookphysio-booking-btn {
    width: 100%;
  }
  
.main-content {
  margin: 110px;
  text-align: center;
}

.home-button {
  padding: 5px var(--pd-btn-booking);
  color: white;
  border: 0px solid transparent;
  border-radius: 7px;
  background-color: #007bff;
  box-sizing: border-box;
  transition: all 0.3s;
}

.error {
  font-size: 150px;
}

.not-found {
  font-size: 28px;
}

@media (max-width : 481px) {
  .error {
    font-size: 80px;
  }

  .not-found {
    font-size: 20px;
  }

  .go-back {
    font-size: 10px;
  }

  .main-content {
    margin: 50px;
    text-align: center;
  }
}